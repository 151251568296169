/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Subtitle, Image } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1k3yqxq bg--center --full" name={"dienstleistungen"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/124/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" anim={"6"} animS={"5"} style={{"maxWidth":1080}}>
              
              <Title className="title-box fs--72" content={"<span style=\"color: white;\">Wir führen Reparaturen<br>und Wartung von Fahrzeugen durch</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn1 btn-box--cColor1" href={"#kontakt"} content={"Sie finden uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--50" name={"dienstleistungen-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":700}} content={"Wir haben mehr als 5000 Reparaturen pro Jahr"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left" name={"dienstleistungen-3"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--3" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Reifenwechsel"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Profiltiefenmessung"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Geometrieeinstellung"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Geometrieeinstellung"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Ölwechsel"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center mb--30" style={{"maxWidth":900}}>
              
              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Kratzer im Autolack entfernen und polieren"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--60" name={"fotogallerie"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/124/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/124/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/124/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/124/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/124/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/124/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/124/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/124/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/124/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/124/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/124/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/124/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/124/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/124/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/124/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/124/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/124/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/124/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/124/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/124/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/124/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/124/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/124/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/124/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--30" name={"kontakt"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 pb--30 pt--30" anim={"2"} animS={"5"} style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":900}}>
              
              <Title className="title-box" style={{"maxWidth":700}} content={"Oeder Weg 22<br>+49 797 811 2X05<br>info@vase-stranky.com"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":""}} content={"<a href=\"https://saywebpage.com\">Webproduktion stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}